<template>
  <div>
    <a-card :bordered="false">
      <s-table
        ref="table"
        size="small"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :pageNum="pageNum"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleCreate(record)">审批</a>
            <a-divider type="vertical"/>
            <a @click="handleWatch(record)">查看</a>
            <a-divider type="vertical"/>
            <a @click="handleBill(record)">关联账单</a>
          </template>
        </span>
      </s-table>
    </a-card>
    <approve-form
      ref="createModal"
      v-if="visible"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import ApproveForm from '@/views/a-approve/admin/ApproveForm'

import { getApproveToDoList, doApproveToDo } from '@/api/design'
export default {
  name: 'TableShenPiToDoList',
  components: {
    STable,
    ApproveForm
  },
  data () {
    return {
      pageNum: 1,
      loading: false,
      visible: false,
      confirmLoading: false,
      mdl: {},
      // 查询参数
      queryParam: {
        my_approval: true
      },
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '类型',
          dataIndex: 'business_type',
          customRender: (text) => this.$Dictionaries.business_type[text] || '无'
        },
        {
          title: '名称',
          dataIndex: 'approval_name'
        },
        {
          title: '进度',
          dataIndex: 'process_node',
          customRender: (text) => text === '0-0' ? '' : text
        },
        {
          title: '时间',
          dataIndex: 'last_update'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '180px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      dispatchBill: {
        'water_bill': '/water/index/',
        'electricity_bill': '/electricity/payment/',
        'rent_bill': '/rent_bill/index/',
        'property_bill': '/property_bill/index/',
        'contract_bill': '/rent_contract/index/',
        'guarantee_bill': '/guarantee/index/'
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return getApproveToDoList(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {
    this.setCurrentPage()
  },
  methods: {
    setCurrentPage () {
      // const page = sessionStorage.getItem('RentCurrentPage')
      const page = this.$store.getters.pop
      if (page) {
        console.log(page)
        const current = page.split('#$$#')[0]
        const query = page.split('#$$#')[1]
        const path = page.split('#$$#')[2]
        sessionStorage.removeItem('RentCurrentPage')
        if (path === this.$route.path) {
          if (!isNaN(Number(current))) {
            this.pageNum = Number(current)
          }
          if (query) {
            this.queryParam = JSON.parse(query)
          }
        } else {
          this.queryParam = { my_approval: true }
          this.$store.dispatch('clear')
        }
      }
    },
    handleCreate (record) {
      this.mdl = record
      this.visible = true
      this.confirmLoading = false
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          this.confirmLoading = false
          this.visible = false
          if (this.mdl) {
            this.handleApprove(this.mdl, values)
          }
        } else {
          this.confirmLoading = false
        }
        this.mdl = {}
      })
    },
    handleApprove (item, data) {
      console.log(item, data)
      doApproveToDo(item.id, data).then((res) => {
        console.log(res, '审批结果')
        this.$refs.table.refresh()
      })
    },
    handleEdit (item) {
      console.log(item)
      this.mdl = item
      this.visible = true
      this.confirmLoading = false
    },
    handleWatch (item) {
      console.log(item)
      // sessionStorage.setItem('RentCurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$store.dispatch('push', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$router.push({
        path: '/users/approval/todo/' + item.id + '/',
        query: {
          type: item.business_type
        }
      })
    },
    handleBill (item) {
      console.log(item)
      // sessionStorage.setItem('RentCurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$store.dispatch('push', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$router.push({
        path: this.dispatchBill[item.business_type] + item.data_id + '/'
      })
    }
  }
}
</script>
